import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BadgePage from 'src/components/badge-page';

export default function Page() {
    const { allStrapiSkill } = useStaticQuery(
        graphql`
            query skillsQuery {
                allStrapiSkill {
                    skills: nodes {
                        name
                        tags {
                            color
                            name
                        }
                        url
                    }
                }
            }
        `
    );

    const { skills } = allStrapiSkill;

    return <BadgePage badges={skills} title="Technologies I've Worked With" />;
}
